/**
* Template Name: Selecao
* Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
* Updated: Jul 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */

:root {
    --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway",  sans-serif;
    --nav-font: "Poppins",  sans-serif;
  }
  
  /* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
  :root { 
    --background-color: #ffffff; /* Background color for the entire website, including individual sections */
    --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
    --heading-color: #2a2c39; /* Color for headings, subheadings and title throughout the website */
    --accent-color: #ef6603; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
  }
  
  /* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
  :root {
    --nav-color: #ffffff;  /* The default color of the main navmenu links */
    --nav-hover-color: #ef6603; /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #060606; /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #ef6603; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
  }
  
  /* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */
  
  .light-background {
    --background-color: #f9f9f9;
    --surface-color: #ffffff;
  }
  
  .dark-background {
    --background-color: #2a2c39;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #404356;
    --contrast-color: #ffffff;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /* PHP Email Form Messages
  ------------------------------*/
  .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .php-email-form .loading {
    display: none;
    background: var(--surface-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--surface-color);
    animation: php-email-form-loading 1s linear infinite;
  }
  
  @keyframes php-email-form-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
   background-color: #2a2c39;
    color: var(--default-color);
    /* background-color: var(--background-color); */
    padding: 20px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 70px;
    /* margin-right: 8px; */
  }
  
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
  }
  
  /* Global Header on Scroll
  ------------------------------*/
  .scrolled .header {
    --background-color: rgba(42, 44, 57, 0.9);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Navmenu - Desktop */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
      margin-left: 45%;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
      margin-left: 5px;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      padding: 8px 20px;
      font-size: 14px;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
      border-radius: 50px;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      background-color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 0;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 15px;
      z-index: 99;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
      margin-left: 0;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      background-color: transparent;
      color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 105%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Navmenu - Mobile */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-mobile-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
    position: relative;
  }
  
  .footer h3 {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    padding: 0;
    margin: 0 0 15px 0;
  }
  
  .footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 30px 0;
  }
  
  .footer .social-links {
    margin: 0 0 30px 0;
  }
  
  .footer .social-links a {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-color);
    color: var(--contrast-color);
    line-height: 1;
    margin: 0 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
    text-decoration: none;
  }
  
  .footer .copyright {
    padding-top: 25px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .credits {
    font-size: 13px;
    padding-top: 5px;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    background-color: var(--background-color);
    transition: all 0.6s ease-out;
    width: 100%;
    height: 100vh;
  }
  
  #preloader:before,
  #preloader:after {
    content: "";
    position: absolute;
    border: 4px solid var(--accent-color);
    border-radius: 50%;
    animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  #preloader:after {
    animation-delay: -0.5s;
  }
  
  @keyframes animate-preloader {
    0% {
      width: 10px;
      height: 10px;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      opacity: 1;
    }
  
    100% {
      width: 72px;
      height: 72px;
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 160px 0 80px 0;
    text-align: center;
    position: relative;
  }
  
  .page-title:before {
    content: "";
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
  }
  
  .page-title h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .page-title .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .page-title .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .page-title .breadcrumbs ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 77px;
    overflow: clip;
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 26px;
    font-weight: 600;
    padding: 0;
    line-height: 1px;
    margin: 0;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: var(--accent-color);
    margin: 4px 10px;
  }
  
  .section-title p {
    color: var(--heading-color);
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, var(--background-color) 0%, color-mix(in srgb, var(--background-color) 90%, white 10%) 100%);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;
  }
  
  .hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    min-height: 75vh;
    padding-top: 60px;
  }
  
  .hero h2 {
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
  }
  
  .hero h2 span {
    text-decoration: underline;
  }
  
  .hero p {
    max-width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
  }
  
  .hero .carousel-control-prev,
  .hero .carousel-control-next {
    width: 10%;
  }
  
  .hero .carousel-control-next-icon,
  .hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  .hero .btn-get-started {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    animation-delay: 0.8s;
    color: var(--default-color);
    border: 2px solid var(--accent-color);
  }
  
  .hero .btn-get-started:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
    text-decoration: none;
  }
  
  @media (min-width: 1024px) {
    .hero p {
      max-width: 60%;
    }
  
    .hero .carousel-control-prev,
    .hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 768px) {
    .hero .carousel-container {
      min-height: 90vh;
    }
  
    .hero h2 {
      font-size: 28px;
    }
  }
  
  .hero .hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
  }
  
  .hero .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
    opacity: 0.6;
  }
  
  .hero .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
    opacity: 0.4;
  }
  
  .hero .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
  }
  
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
  
    100% {
      transform: translate(-90px, 0%);
    }
  }
  
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about ul {
    list-style: none;
    padding: 0;
  }
  
  .about ul li {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .about .read-more {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .about .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
  }
  
  .about .read-more:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .about .read-more:hover i {
    transform: translate(5px, 0);
  }
  
  /*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
  .features .nav-tabs {
    border: 0;
  }
  
  .features .nav-link {
    background-color: var(--surface-color);
    color: var(--heading-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    padding: 15px 20px;
    transition: 0.3s;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
  }
  
  .features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .features .nav-link:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .features .nav-link.active {
    background: var(--accent-color);
    color: var(--contrast-color);
    border-color: var(--accent-color);
  }
  
  .features .nav-link.active h4 {
    color: var(--contrast-color);
  }
  
  @media (max-width: 768px) {
    .features .nav-link i {
      padding: 0;
      line-height: 1;
      font-size: 36px;
    }
  }
  
  @media (max-width: 575px) {
    .features .nav-link {
      padding: 15px;
    }
  
    .features .nav-link i {
      font-size: 24px;
    }
  }
  
  .features .tab-content {
    margin-top: 30px;
  }
  
  .features .tab-pane h3 {
    color: var(--heading-color);
    font-weight: 700;
    font-size: 26px;
  }
  
  .features .tab-pane ul {
    list-style: none;
    padding: 0;
  }
  
  .features .tab-pane ul li {
    padding-bottom: 10px;
  }
  
  .features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .features .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    padding: 80px 0;
    position: relative;
    clip-path: inset(0);
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    color: var(--default-color);
    font-size: 28px;
    font-weight: 700;
  }
  
  .call-to-action p {
    color: var(--default-color);
  }
  
  .call-to-action .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .call-to-action .cta-btn:hover {
    background: color-mix(in srgb, var(--accent-color) 90%, white 15%);
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services .service-item {
    background-color: var(--surface-color);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 30px 60px 70px;
    transition: all ease-in-out 0.3s;
    border-radius: 18px;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .services .service-item .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
  }
  
  .services .service-item .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
  }
  
  .services .service-item h3 {
    font-weight: 700;
    margin: 10px 0 15px 0;
    font-size: 22px;
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  @media (min-width: 1365px) {
    .services .service-item:hover {
      transform: translateY(-10px);
    }
  
    .services .service-item:hover h3 {
      color: var(--accent-color);
    }
  }
  
  /*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
  /* Portfolio styles */
/* Ensure images fit within their containers */
.img-style {
  width: 100%;
  height: auto;
  max-width: 100%;  /* Make sure the image does not exceed the container width */
  display: block;
  border-radius: 30px;
}

/* Flexbox for image layout */
.isotope-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.portfolio-item {
  flex: 1 1 calc(16.666% - 1rem); /* 6 images per row on large screens */
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .portfolio-item {
    flex: 1 1 calc(33.333% - 1rem); /* 3 images per row on medium screens */
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    flex: 1 1 calc(50% - 1rem); /* 2 images per row on tablets and mobile */
  }
}

@media (max-width: 480px) {
  .portfolio-item {
    flex: 1 1 calc(50% - 1rem); /* 2 images per row on smaller mobile devices */
  }
}

/* Overlay style for the view button */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  border-radius: 30px;
}

.portfolio-image-wrapper {
  position: relative;
  overflow: hidden;
}

.portfolio-image-wrapper:hover .overlay {
  opacity: 1;
}

.view-button {
  color: #fff;
  font-size: 1rem;
  background-color: #ef6603;
  padding: 0.2rem 1.2rem;
  border-radius: 20px;
  font-style: italic;
  /* border: 1px solid #fff; */
}
.links{
  color: #fff;
  text-decoration: none;
}

  
  /*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
  .testimonials .testimonial-item {
    background-color: var(--surface-color);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    padding: 30px;
    margin: 30px 15px;
    position: relative;
    height: 100%;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    margin-right: 15px;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin: 0;
  }
  
  .testimonials .testimonial-item .stars {
    margin: 10px 0;
  }
  
  .testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
  }
  
  .testimonials .swiper-wrapper {
    height: auto;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  @media (max-width: 767px) {
    .testimonials .testimonial-wrap {
      padding-left: 0;
    }
  
    .testimonials .testimonial-item {
      padding: 30px;
      margin: 15px;
    }
  
    .testimonials .testimonial-item .testimonial-img {
      position: static;
      left: auto;
    }
  }


  .ftco-section{
    background-color:  #ef6603;
  }
  .block-18{
    font-size: 20px;
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
  .pricing .pricing-item {
    background-color: var(--surface-color);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing .pricing-item h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    background: color-mix(in srgb, var(--default-color), transparent 95%);
  }
  
  .pricing .pricing-item h4 {
    font-size: 36px;
    font-weight: 600;
    font-family: var(--heading-font);
  }
  
  .pricing .pricing-item h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing .pricing-item h4 span {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing .pricing-item ul {
    padding: 15px 0;
    list-style: none;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing .pricing-item ul li {
    padding-bottom: 16px;
  }
  
  .pricing .pricing-item ul i {
    color: var(--accent-color);
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing .pricing-item ul .na {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    background: color-mix(in srgb, var(--default-color), transparent 95%);
    margin: 0 -20px -20px -20px;
    padding: 20px 15px;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: var(--accent-color);
    color: var(--contrast-color);
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 4px;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--heading-font);
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .pricing .featured h3 {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .pricing .advanced {
    background: var(--accent-color);
    color: var(--contrast-color);
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
  }
  
  /*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
  .faq .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .faq .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .faq p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .faq .custom-accordion .accordion-item {
    background-color: var(--surface-color);
    margin-bottom: 0px;
    position: relative;
    border-radius: 0px;
    overflow: hidden;
  }
  
  .faq .custom-accordion .accordion-item .btn-link {
    display: block;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    text-align: left;
    color: var(--default-color);
    border: none;
    padding-left: 40px;
    border-radius: 0;
    position: relative;
    background-color: color-mix(in srgb, var(--default-color), transparent 94%);
  }
  
  .faq .custom-accordion .accordion-item .btn-link:before {
    content: "\f282";
    display: inline-block;
    font-family: "bootstrap-icons" !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
  
  .faq .custom-accordion .accordion-item .btn-link[aria-expanded=true] {
    color: var(--accent-color);
  }
  
  .faq .custom-accordion .accordion-item .btn-link[aria-expanded=true]:before {
    font-family: "bootstrap-icons" !important;
    content: "\f286";
    position: absolute;
    color: var(--accent-color);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .faq .custom-accordion .accordion-item .accordion-body {
    padding: 20px 20px 20px 20px;
    color: var(--default-color);
  }
  
  /*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
  .team .team-member {
    background-color: var(--surface-color);
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
  
  .team .team-member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .team-member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
  }
  
  .team .team-member .social a {
    background: color-mix(in srgb, var(--contrast-color), transparent 25%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0 3px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    transition: ease-in-out 0.3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .team .team-member .social a:hover {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
  
  .team .team-member .social i {
    font-size: 18px;
    line-height: 0;
  }
  
  .team .team-member .member-info {
    padding: 25px 15px;
  }
  
  .team .team-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .team .team-member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .team .team-member:hover .social {
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Recent Posts Section
  --------------------------------------------------------------*/
  .recent-posts article {
    background: var(--surface-color);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .recent-posts .post-img {
    max-height: 240px;
    margin: -30px -30px 15px -30px;
    overflow: hidden;
  }
  
  .recent-posts .post-category {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 10px;
  }
  
  .recent-posts .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .recent-posts .title a {
    color: var(--heading-color);
    transition: 0.3s;
  }
  
  .recent-posts .title a:hover {
    color: var(--accent-color);
  }
  
  .recent-posts .post-author-img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .recent-posts .post-author {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .recent-posts .post-date {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 0;
  }
  .Special{
    width: 65px;
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-item+.info-item {
    margin-top: 40px;
  }
  
  .contact .info-item i {
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .info-item:hover i {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .contact .php-email-form {
    height: 100%;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 25%);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .swiper-wrapper {
    height: auto;
  }
  
  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  
  .portfolio-details .swiper-button-prev:after,
  .portfolio-details .swiper-button-next:after {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  
  .portfolio-details .swiper-button-prev:hover:after,
  .portfolio-details .swiper-button-next:hover:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 575px) {
  
    .portfolio-details .swiper-button-prev,
    .portfolio-details .swiper-button-next {
      display: none;
    }
  }
  
  .portfolio-details .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-info h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    bottom: 0;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  
  .portfolio-details .portfolio-info ul strong {
    text-transform: uppercase;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
  }
  
  .portfolio-details .portfolio-info .btn-visit {
    padding: 8px 40px;
    background: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .portfolio-details .portfolio-info .btn-visit:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item {
    padding: 30px 30px 0 30px;
    position: relative;
    background: color-mix(in srgb, var(--default-color), transparent 97%);
    margin-bottom: 50px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    float: left;
    margin: 0 10px 0 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px 0;
    padding-top: 20px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h4 {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .portfolio-details .portfolio-description .testimonial-item p {
    font-style: italic;
    margin: 0 0 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .service-box {
    background-color: var(--surface-color);
    padding: 20px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }
  
  .service-details .service-box+.service-box {
    margin-top: 30px;
  }
  
  .service-details .service-box h4 {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .service-details .services-list {
    background-color: var(--surface-color);
  }
  
  .service-details .services-list a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    display: flex;
    align-items: center;
    padding: 12px 15px;
    margin-top: 15px;
    transition: 0.3s;
  }
  
  .service-details .services-list a:first-child {
    margin-top: 0;
  }
  
  .service-details .services-list a i {
    font-size: 16px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .services-list a.active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .service-details .services-list a.active i {
    color: var(--contrast-color);
  }
  
  .service-details .services-list a:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a {
    color: var(--default-color);
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: 0.3s;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .service-details .download-catalog a:first-child {
    border-top: 0;
    padding-top: 0;
  }
  
  .service-details .download-catalog a:last-child {
    padding-bottom: 0;
  }
  
  .service-details .download-catalog a i {
    font-size: 24px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a:hover {
    color: var(--accent-color);
  }
  
  .service-details .help-box {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    margin-top: 30px;
    padding: 30px 15px;
  }
  
  .service-details .help-box .help-icon {
    font-size: 48px;
  }
  
  .service-details .help-box h4,
  .service-details .help-box a {
    color: var(--contrast-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
 
  


  .specialization-container {
    text-align: center;
    padding: 50px 85px;
}

.specialization-container h4 {
    margin-bottom: 20px;
    font-size: 24px;
  
}

.specialization-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 44px;
}

.specialization-grid img {
    /* width: 50px; */
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.specialization-grid img:hover {
    transform: scale(1.1);
}
  /*--------------------------------------------------------------
  # Blog Posts Section
  --------------------------------------------------------------*/
  .blog-posts article {
    background-color: var(--surface-color);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .blog-posts .post-img {
    max-height: 240px;
    margin: -30px -30px 15px -30px;
    overflow: hidden;
  }
  
  .blog-posts .post-category {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 10px;
  }
  
  .blog-posts .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog-posts .title a {
    color: var(--heading-color);
    transition: 0.3s;
  }
  
  .blog-posts .title a:hover {
    color: var(--accent-color);
  }
  
  .blog-posts .post-author-img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .blog-posts .post-author {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .blog-posts .post-date {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Blog Pagination Section
  --------------------------------------------------------------*/
  .blog-pagination {
    padding-top: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog-pagination li a {
    color: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid var(--accent-color);
  }
  
  .blog-pagination li a.active,
  .blog-pagination li a:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .blog-pagination li a.active a,
  .blog-pagination li a:hover a {
    color: var(--contrast-color);
  }
  
  /*--------------------------------------------------------------
  # Blog Details Section
  --------------------------------------------------------------*/
  .blog-details {
    padding-bottom: 30px;
  }
  
  .blog-details .article {
    background-color: var(--surface-color);
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog-details .post-img {
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog-details .title {
    color: var(--heading-color);
    font-size: 28px;
    font-weight: 700;
    padding: 0;
    margin: 30px 0;
  }
  
  .blog-details .content {
    margin-top: 20px;
  }
  
  .blog-details .content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog-details .content blockquote {
    overflow: hidden;
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog-details .content blockquote p {
    color: var(--default-color);
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog-details .content blockquote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--accent-color);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog-details .meta-top {
    margin-top: 20px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-details .meta-top ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog-details .meta-top ul li+li {
    padding-left: 20px;
  }
  
  .blog-details .meta-top i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-details .meta-top a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog-details .meta-bottom {
    padding-top: 10px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .blog-details .meta-bottom i {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    display: inline;
  }
  
  .blog-details .meta-bottom a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    transition: 0.3s;
  }
  
  .blog-details .meta-bottom a:hover {
    color: var(--accent-color);
  }
  
  .blog-details .meta-bottom .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .blog-details .meta-bottom .cats li {
    display: inline-block;
  }
  
  .blog-details .meta-bottom .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .blog-details .meta-bottom .tags li {
    display: inline-block;
  }
  
  .blog-details .meta-bottom .tags li+li::before {
    padding-right: 6px;
    color: var(--default-color);
    content: ",";
  }
  
  .blog-details .meta-bottom .share {
    font-size: 16px;
  }
  
  .blog-details .meta-bottom .share i {
    padding-left: 5px;
  }
  
  /*--------------------------------------------------------------
  # Blog Comments Section
  --------------------------------------------------------------*/
  .blog-comments {
    padding: 10px 0;
  }
  
  .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog-comments .comment h5 a {
    font-weight: bold;
    color: var(--default-color);
    transition: 0.3s;
  }
  
  .blog-comments .comment h5 a:hover {
    color: var(--accent-color);
  }
  
  .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
  }
  
  .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 5px;
  }
  
  .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  /*--------------------------------------------------------------
  # Comment Form Section
  --------------------------------------------------------------*/
  .comment-form {
    padding-top: 10px;
  }
  
  .comment-form form {
    background-color: var(--surface-color);
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .comment-form form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .comment-form form p {
    font-size: 14px;
  }
  
  .comment-form form input {
    background-color: var(--surface-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    font-size: 14px;
    border-radius: 4px;
    padding: 10px 10px;
  }
  
  .comment-form form input:focus {
    color: var(--default-color);
    background-color: var(--surface-color);
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .comment-form form input::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .comment-form form textarea {
    background-color: var(--surface-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
    height: 120px;
  }
  
  .comment-form form textarea:focus {
    color: var(--default-color);
    box-shadow: none;
    border-color: var(--accent-color);
    background-color: var(--surface-color);
  }
  
  .comment-form form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .comment-form form .form-group {
    margin-bottom: 25px;
  }
  
  .comment-form form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .comment-form form .btn-primary:hover {
    color: var(--contrast-color);
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  /*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
  .widgets-container {
    margin: 60px 0 30px 0;
  }
  
  .widget-title {
    color: var(--heading-color);
    font-size: 20px;
    font-weight: 600;
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
    border-left: 4px solid var(--accent-color);
  }
  
  .widget-item {
    margin-bottom: 30px;
    background-color: color-mix(in srgb, var(--default-color), transparent 98%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
    border-radius: 5px;
  }
  
  .widget-item:last-child {
    margin-bottom: 0;
  }
  
  .blog-author-widget img {
    max-width: 160px;
  }
  
  .blog-author-widget h4 {
    font-weight: 600;
    font-size: 24px;
    margin: 15px 0 0 0;
    padding: 0;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
  }
  
  .blog-author-widget .social-links {
    margin: 5px 0;
  }
  
  .blog-author-widget .social-links a {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    margin: 0 3px;
    font-size: 18px;
  }
  
  .blog-author-widget .social-links a:hover {
    color: var(--accent-color);
  }
  
  .blog-author-widget p {
    font-style: italic;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin: 10px 0 0 0;
  }
  
  .search-widget form {
    background: var(--background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 75%);
    padding: 3px 10px;
    position: relative;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .search-widget form input[type=text] {
    border: 0;
    padding: 4px 10px;
    border-radius: 4px;
    width: calc(100% - 40px);
    background-color: var(--background-color);
    color: var(--default-color);
  }
  
  .search-widget form input[type=text]:focus {
    outline: none;
  }
  
  .search-widget form button {
    background: none;
    color: var(--default-color);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 16px;
    transition: 0.3s;
    line-height: 0;
  }
  
  .search-widget form button i {
    line-height: 0;
  }
  
  .search-widget form button:hover {
    color: var(--accent-color);
  }
  
  .search-widget form:is(:focus-within) {
    border-color: var(--accent-color);
  }
  
  .categories-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .categories-widget ul li {
    padding-bottom: 10px;
  }
  
  .categories-widget ul li:last-child {
    padding-bottom: 0;
  }
  
  .categories-widget ul a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: 0.3s;
  }
  
  .categories-widget ul a:hover {
    color: var(--accent-color);
  }
  
  .categories-widget ul a span {
    padding-left: 5px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
  }
  
  .recent-posts-widget .post-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .recent-posts-widget .post-item:last-child {
    margin-bottom: 0;
  }
  
  .recent-posts-widget .post-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .recent-posts-widget .post-item h4 a {
    color: var(--default-color);
    transition: 0.3s;
  }
  
  .recent-posts-widget .post-item h4 a:hover {
    color: var(--accent-color);
  }
  
  .recent-posts-widget .post-item time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .tags-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .tags-widget ul li {
    display: inline-block;
  }
  
  .tags-widget ul a {
    background-color: color-mix(in srgb, var(--default-color), transparent 94%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    border-radius: 50px;
    font-size: 14px;
    padding: 5px 15px;
    margin: 0 6px 8px 0;
    display: inline-block;
    transition: 0.3s;
  }
  
  .tags-widget ul a:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .tags-widget ul a span {
    padding-left: 5px;
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    font-size: 14px;
  }
 
  /* Hide the regular navigation menu on small screens */
@media (max-width: 786px) {
  /* .navmenu {
    display: none;
  } */
  .mobile-nav-toggle {
    display: block;
  }
  .bi-list{
    color: #fff;
    border: 1px solid #fff;
    padding:5px 10px ;
  }
}

/* Show the regular navigation menu and hide the toggle button on larger screens */
/* @media (min-width: 787px) {
  .mobile-nav-toggle {
    display: none;
  }
} */
/* offcanvas.css */
.offcanvas-end {
  background-color: #f8f9fa; /* Light background color */
  width: 250px; /* Width of the off-canvas menu */
  padding: 20px; /* Padding inside the menu */
}

.offcanvas-body {
  padding: 0; /* Remove default padding */
}

.offcanvas-header {
  border-bottom: 1px solid #dee2e6; /* Border at the bottom of the header */
}

.offcanvas-title {
  font-size: 1.5rem; /* Larger font size for the title */
  font-weight: bold; /* Bold font weight */
}

.btn-close {
  background: none; /* Remove background from the close button */
}

.offcanvas-body ul {
  list-style-type: none; /* Remove bullets from list items */
  padding: 0; /* Remove padding from the list */
}

.offcanvas-body ul li {
  margin: 10px 0; /* Space between list items */
}

.offcanvas-body ul li a {
  text-decoration: none; /* Remove underline from links */
  color: #000; /* Color for links */
  font-size: 1.2rem; /* Larger font size for links */
}

.offcanvas-body ul li a.active {
  font-weight: bold; /* Make active link bold */
  color: #ef6603; /* Different color for active link */
}
.offcanvas{
  width:200px
}
/* main.css */
#offcanvasMenu {
  width: 200px; /* Adjust the width as needed */
}

@media (min-width: 576px) {
  #offcanvasMenu {
    max-width: 250px; /* Ensures the off-canvas doesn't exceed this width */
  }
}
/* VirtualAssistant.css */

/* Hero Section */
.virtual-assistant-container .hero-section {
  background-image: url("../image/bhb.webp"); /* Replace with your own background */
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.virtual-assistant-container .overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* About Section */
.bhagavad-gita{
  margin-top: 7%;
}
.sitenaming{
  width: 50%;
}
.bengali-img{
  width: 100%;
}
.playstore{
  width: 60%;
  height: 60px;
  margin-left: 30px;
  margin-top: 12px;
}
.ios{
  width: 75%;
 
  height: 100%;
  margin-top: 5px;
  margin-right: 30px;

}
@media(min-width:1900px){
  .ios {
    width: 80%;
    height: 100%;
    margin-top: -21px;
    margin-right: 30px;
    padding: 27px;
}
}
@media(max-width:756px){
  .playstore{
    width: 30%;
    height: 50px;
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media(max-width:756px){
  .ios{
    width: 35%;
    height: 55px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 7px;
  }
}
.mobile-view{
  display: flex;
  margin-top: 5rem;
}
@media(max-width:756px){
  .mobile-view{
    display: block;
    margin-top: 8rem;
  }
}
.local-dev{
  margin-top: 2rem;
}

.tamilcal{
  width:50%;
}
.tamil-calendar-img{
  width:100%;

}
.playios-link{
  display: flex;
  justify-content: center;
}
@media(max-width:786px){
  .playios-link{
    display: block;
  } 
}